import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

import Grid from "../components/grid"
import { linkResolver } from "../utils/linkResolver"

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!"

  return <Grid>Loading</Grid>
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: "gauschfilm",
  linkResolver,
})
