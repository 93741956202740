exports.linkResolver = ({ node, key, link }) => (doc) => {
  if (doc.isBroken) return "./not-found"

  switch (doc.type) {
    case "home":
      return "/"
    case "about":
      return "/" + doc.uid
    case "clients":
      return "/" + doc.uid
    case "contact":
      return "/" + doc.uid
    case "legal":
      return "/legal?filter=" + doc.uid

    case "project":
      return "/portfolio/" + doc.uid
    default:
      // Homepage route fallback
      return "/"
  }
}
